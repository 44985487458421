import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  private readonly title: Title = inject(Title);
  private readonly translate: TranslocoService = inject(TranslocoService);

  constructor() {
    super();
  }

  public override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);

    let pageTitle: string = this.translate.translate('main.title');

    if (title !== undefined) {
      const titleTranslate = this.translate.translate(title);
      pageTitle = `${pageTitle} - ${titleTranslate}`;
    }

    this.title.setTitle(pageTitle);
  }
}
