import {
  ApplicationConfig,
  provideZoneChangeDetection,
  isDevMode,
  APP_INITIALIZER,
  LOCALE_ID,
  DEFAULT_CURRENCY_CODE,
} from '@angular/core';
import {
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
  GuardsCheckEnd,
  NavigationEnd,
} from '@angular/router';
import { provideNgProgressRouter } from 'ngx-progressbar/router';
import { progressInterceptor } from 'ngx-progressbar/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  Translation,
  TranslocoService,
  provideTransloco,
} from '@jsverse/transloco';
import { provideServiceWorker } from '@angular/service-worker';
import { registerLocaleData, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

import localeEs from '@angular/common/locales/es-MX';
import localeDeExtra from '@angular/common/locales/extra/es-MX';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { TranslocoHttpLoader } from '@/transloco-loader';
import { MessageService } from 'primeng/api';

import { routes } from '@/app.routes';
import { PageTitleStrategy } from '@/page-title.strategy';
import { AuthService } from '@/modules/auth/services/auth.service';
import { environment as ENV } from '@environments/environment';
import { authInterceptor } from '@/modules/core/interceptors/auth.interceptor';
import { StorageService } from '@/modules/core/services/storage.service';
import { messageInterceptor } from '@/modules/core/interceptors/message.interceptor';

registerLocaleData(localeEs, 'es-MX', localeDeExtra);

function appInitializerAppMe(
  storageService: StorageService,
  authService: AuthService
) {
  return async (): Promise<boolean> => {
    if (storageService.get('accessToken')) {
      try {
        const me = await firstValueFrom(authService.me());

        if (me.data?.roles?.length) {
          authService.setUserMe(me.data);
        } else {
          authService.logout();
        }
        return true;
      } catch {
        authService.logout();
        return true;
      }
    }

    return true;
  };
}

export function appInitializerTransloco(translateService: TranslocoService) {
  return (): Promise<Translation> => {
    return lastValueFrom(translateService.load('es'));
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        authInterceptor,
        messageInterceptor,
        progressInterceptor,
      ])
    ),
    provideTransloco({
      config: {
        availableLangs: ['es'],
        defaultLang: 'es',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerAppMe,
      multi: true,
      deps: [StorageService, AuthService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerTransloco,
      multi: true,
      deps: [TranslocoService],
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-MX',
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {
        timezone: 'UTC',
      },
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: '$' },
    provideNgProgressRouter({
      startEvents: [GuardsCheckEnd],
      completeEvents: [NavigationEnd],
    }),
    MessageService,
    provideServiceWorker('ngsw-worker.js', {
      enabled: ENV.enableServiceWorkers,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
