import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { EMPTY, catchError, throwError } from 'rxjs';

import { SKIP_AUTH } from '@/modules/core/tokens';
import { AuthService } from '@/modules/auth/services/auth.service';

export function authInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const authService = inject(AuthService);
  const skipAuth = request.context.get(SKIP_AUTH);

  if (skipAuth) {
    return next(request);
  }

  return next(updateRequestHeaders(request, authService.getAccessToken())).pipe(
    catchError((error: HttpErrorResponse) => {
      if (!isUrlInWhiteList(request.url) && [401].includes(error.status)) {
        authService.logout();
        return EMPTY;
      }
      return throwError(() => error);
    })
  );
}

function updateRequestHeaders(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: HttpRequest<any>,
  accessToken: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): HttpRequest<any> {
  let headers: HttpHeaders;
  if (!request.headers.has('x-ignore-update-interceptor')) {
    headers = request.headers.append('Content-Type', 'application/json');
  } else {
    headers = request.headers.delete('x-ignore-update-interceptor');
  }

  if (!request.headers.has('Authorization') && accessToken) {
    headers = headers.append('Authorization', `Bearer ${accessToken}`);
  }

  return request.clone({
    headers,
  });
}

function isUrlInWhiteList(url: string): boolean {
  return ['/v1/auth/login/panel'].some(_url => url.includes(_url));
}
