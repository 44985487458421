import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';

import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  public getTranslation(lang: string): Observable<Translation> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}
