import { Routes } from '@angular/router';
import { authGuard, loginGuard } from '@/modules/auth/guards';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@/modules/auth/auth.routes').then(m => m.routes),
    canActivate: [loginGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('@/modules/dashboard-layout/dashboard-layout.routes').then(
        m => m.routes
      ),
    canActivate: [authGuard],
  },
  {
    path: '**',
    loadChildren: () =>
      import('@/modules/not-found/not-found.routes').then(m => m.routes),
  },
];
