import { ApiResponse } from '@/models/api';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, tap, throwError } from 'rxjs';

import { SHOW_SUCCESS_MESSAGE } from '@/modules/core/tokens';

export function messageInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const messageService = inject(MessageService);
  const showSucessMessage = request.context.get(SHOW_SUCCESS_MESSAGE);

  return next(request).pipe(
    tap(event => {
      if (
        event.type === HttpEventType.Response &&
        event.ok &&
        showSucessMessage
      ) {
        const response: ApiResponse = event.body as ApiResponse;
        messageService.add({
          severity: 'success',
          detail: response.message || 'Solicitud procesada correctamente',
          summary: 'Correcto',
        });
      }
    }),
    catchError((error: HttpErrorResponse) => {
      const errorMessage = error.error?.data
        ? error.error?.data[0]
        : error.message;
      messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
        life: 3000,
      });
      return throwError(() => error);
    })
  );
}
