import { Component, inject } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { NgProgressbar } from 'ngx-progressbar';
import { NgProgressRouter } from 'ngx-progressbar/router';
import { ToastModule } from 'primeng/toast';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgProgressHttp } from 'ngx-progressbar/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    NgProgressbar,
    NgProgressRouter,
    NgProgressHttp,
    ToastModule,
    ConfirmDialogModule,
    TranslocoModule,
  ],
  template: `
    <p-confirmDialog>
      <ng-template pTemplate="message" let-message>
        <div class="flex flex-column align-items-center gap-3 surface-border">
          <i
            class="pi pi-exclamation-circle text-6xl text-orange-500 text-warning"></i>
          <p class="my-2 text-bold">{{ message.message }}</p>
        </div>
      </ng-template>
    </p-confirmDialog>
    <p-toast position="top-center" />
    <ng-progress [spinner]="true" ngProgressRouter ngProgressHttp />
    <router-outlet />
  `,
  providers: [ConfirmationService],
})
export class AppComponent {
  private readonly swUpdate: SwUpdate = inject(SwUpdate);
  private readonly primengConfig: PrimeNGConfig = inject(PrimeNGConfig);
  private readonly transloco: TranslocoService = inject(TranslocoService);
  private readonly confirmationService: ConfirmationService =
    inject(ConfirmationService);

  constructor() {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation(this.transloco.getTranslation('primeng'));

    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe(() =>
        this.confirmationService.confirm({
          header: this.transloco.translate('newVersion.title'),
          message: this.transloco.translate('newVersion.message'),
          closeOnEscape: false,
          rejectButtonStyleClass: 'p-button-secondary',
          accept: () => location.reload(),
        })
      );
  }
}
